
.inactive {
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
    color: black;

    display: flex;
    align-items: center;
    justify-content: center;
}

.inactive:hover {
    color: var(--color-Redish);
}

.active {
    background-color: #f2f3f5;
    color: var(--color-Redish);
    border-radius: 10px;  
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-section1 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.logo-img {
    height: 40px;
    width: 150px;
    cursor: pointer;
}

.candidate-icons {
    display: flex;
}

.candidate-icon {
    padding: 10px;
    text-decoration: none;
    color: black;
}
/* 
.candidate-icon:hover {
    color: #ed222e;
}

.candidate-icon:active {
    background-color: #f7f8fd;
    color: #ed222e;
    border-radius: 10px;  
    cursor: pointer;
} */

.logout-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
}

.logout {
   
    padding: 0.6rem 1.5rem;
    border: none;
    background-color: var(--color-Redish);
    border-radius: 30px;
    color: white;
    
    font-weight: 600;
    margin-left: 20px;
    cursor: pointer;

}

.logout:hover,
.logout:active,
.logout:focus,
.logout.active {
    background: var(--color-DarkRedish);
    color: #ffffff;
}

:root {
    --color-white: #fff;
    --color-black: #000;
    --color-light: #9da7aa;
    --color-dark: #171819;
    --color-darker: #131414;
    --color-blue: #2662f0;
    --color-blue-light: #8aabf8;
    --color-greenblue1: #34d1bf;
    --color-greenblue2: #27bead;
    --color-border-dark: #1d1f20;
    --color-border-dashed: #494d4e;
    --color-translucent: rgba(23, 24, 25, 0.6);
    --color-orange: #ff6e07;
    --color-lightgreen: #00ff43;
    --color-Redish: #ED202D;
    --color-DarkRedish: #d10916;
}


.container-styled {
    margin: 0 -45px;
}

.BGColor {
    background-color: var(--color-Redish);
}

.bg-image {
    background-image: url('../assets/Bg_Vector.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

.hrLine {
    width: 10%;
    size: 10%;
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
    height: 6px !important;
}

.textColor {
    color: var(--color-Redish);
}

.leftBorder {
    border-left: 5px solid var(--color-Redish);
}

.darker:focus {
    background-color: var(--color-DarkRedish);
}

.hidden {
    display: none;
}

.navbarContainerCustom {
    margin-top: 8px;
}