.invalid {
    border: 2px solid #ff0000;
    outline: none;
    background-color: #f7f8fd;
    height: 45px;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.invalidSkills {
    border: 2px solid #2f00ff;
}

.validSkills {
    background-color: none;
    outline: none;
    border: none;
    height: 45px;
}

.invalidColor {
    height: 45px;
    width: 50%;
    border: 2px solid #ff0000;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

}

.colorPicker {
    height: 45px;
    width: 50%;
    border: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.selectUsersPositions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 25vw;
    margin-right: 20px;
}

.selectUsers {
    margin-right: 20px;
}

.styled-table {
    border-collapse: collapse;
    font-size: 0.9rem;
    font-family: sans-serif;
}

.styled-table thead tr {
    text-align: left;
    justify-content: center;
    border: none;
}

.styled-table th {
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 5px 10px;
    border: none;
}

/* .styled-table tbody tr {
    border-bottom: #dddddd;
} */

.styled-table tbody tr:nth-of-type(odd) {
    background-color: #f7f8fd;
}

/* .styled-table tbody tr:nth-of-type(even) {
    background-color: rgb(252, 223, 223);
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid rgb(247, 72, 72);;
} */

.btn {
    height: 2.5rem;
    width: 6rem;
    border-radius: 30px;
    font-weight: 600;
    margin-left: 20px;
    cursor: pointer;
    margin: 1px 2px;
}

.btn-edit {
    border-color: #19a3da;
}

.btn-edit:hover {
    border-color: #19a3da;
    background-color: #19a3da;
    color: white;
}

.btn-delete {
    border-color: rgb(247, 72, 72);
}

.btn-delete:hover {
    background-color: rgb(247, 72, 72);
    border-color: rgb(247, 72, 72);
    color: white;
}


/* Second Css  */


.hr {
    color: #bdb3b3;
    margin-right: -50px;
    margin-left: -50px;
}

.container-custom {
    border-bottom: 3px solid #f7f8fd;
    padding-bottom: 0px;
}

::placeholder {
    color: #d8d8d9 !important;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #d8d8d9 !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #d8d8d9 !important;
}

.candidate-search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.candidate-search-container1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-search-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
}

.search-input {
    border-radius: 8px;
    padding: 10px 10px;
    background-color: #f7f8fd;
}

.search-input .input {
    outline: none;
    border: none;
    padding-left: 15px;
    background-color: #f7f8fd;
    width: 25vw;
    border-right: 1px solid lightgrey;
    margin-right: 10px;
}

.add-candidate-button {
    padding: 0.6rem 1.5rem;
    border: none;
    background-color: #ed222e;
    border-radius: 30px;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.add-candidate-button:hover,
.add-candidate-button:active,
.add-candidate-button:focus,
.add-candidate-button.active {
    background: rgb(218, 1, 1);
    color: #ffffff;
}

.modal-dialog {
    font-weight: 600;
    min-width: 39vw;
}

.dialog-input {
    outline: none;
    background-color: #f7f8fd;
    border: none;
    height: 45px;
}

.modal-footer {
    justify-content: flex-start;
}

.btn2 {
    background-color: red;
    color: white;
}

.btn1 {
    background-color: #f8d4d6;
    color: red;
    margin-right: 15px;
}

.btn1,
.btn2 {
    border-radius: 30px;

    outline: none;
    border: none;
    padding: 8px 40px;
}

.btn2:hover,
.btn2:active,
.btn2:focus,
.btn2.active {
    background: rgb(218, 1, 1);
}

.btn1:hover,
.btn1:active,
.btn1:focus,
.btn1.active {
    background: #f6c5c7;
}

.skillDots {
    padding-right: 5px;
}

span,
.deleteSearchColor {
    color: #ed222e;
}

.deletePositionsButtons-container {
    display: flex;
}

.errorImage{
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.errorImage img {
    width: 50vw;
}

.errorImage button {
    margin-top: 2rem;
}